<template>
  <div>
    <v-container class="container px-0">
      <v-row
        class=""
        :class="`${$vuetify.breakpoint.mdAndDown ? 'flex-column' : ''}`"
      >
        <v-col class="d-flex align-center px-4">
          <v-tabs v-if="serverAccount && !serverAccount.diagnostic_mode" v-model="tab">
            <v-tab
              v-if="service.getUserPrivileges(service.privileges, 'hosting.manage_mysql')"
              v-ripple="false"
            >{{ $t('heading.hosting.databases.databases.title') }}</v-tab>
            <v-tab
              v-if="service.getUserPrivileges(service.privileges, 'hosting.manage_mysql')"
              v-ripple="false"
            >{{ $t('heading.hosting.databases.users.title') }}</v-tab>
            <v-tab
              v-ripple="false"
              v-if="service.getUserPrivileges(service.privileges, 'hosting.phpmyadmin_sso')"
            >{{ $t('heading.hosting.databases.phpmyadmin.title') }}</v-tab>
          </v-tabs>
          <active-server-account-selector
            v-if="service && service.server_accounts.length > 1"
            class="ml-4"
            :value.sync="serverAccount"
            :items="service.server_accounts"
          />
        </v-col>
        <v-col
          v-show="data[tab].items.length"
          class="page-title__filter-controls justify-end"
          :class="[`${$vuetify.breakpoint.mobile ? 'pb-3' : ''}`]"
        >
          <data-iterator-filter-controls
            :keys="data[tab].headers"
            :showSearchTerm="true"
            :searchTerm="data[tab].search"
            :fullWidthSearch="false"
            :fullWidthLg="false"
            :fullWidthMd="true"
            @update:sortBy="changeSortBy"
            @update:sortDesc="handleSortDescChange"
            @update:searchTerm="handleSearchTermChange"
          ></data-iterator-filter-controls>

          <v-btn
            :large="!$vuetify.breakpoint.smAndDown"
            :small="$vuetify.breakpoint.smAndDown"
            elevation="0"
            color="primary"
            class="p-2 add-new-button page-title__add-button"
            @click="addButtons[tab].onClick"
            :loading="data[tab].loading"
          >
            <v-icon :class="$vuetify.breakpoint.mobile ? 'mr-0' : ''"
              >$plus
            </v-icon>
            {{ $vuetify.breakpoint.mobile ? "" : addButtons[tab].text }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-tabs-items v-if="serverAccount && !serverAccount.diagnostic_mode" v-model="tab">
      <v-tab-item :transition="false" v-if="service.getUserPrivileges(service.privileges, 'hosting.manage_mysql')">
        <!-- DATABASES -->

        <v-container class="mt-3 px-0">
          <v-row>
            <v-col cols="12">
              <div :class="checkedItems.length > 0 ? 'pb-15' : ''">
                <hosting-table
                  :headers="data[0].headers"
                  :items="data[0].items"
                  :itemsLoading="data[tab].loading"
                  :itemsPerPage="itemsPerPage"
                  :itemsTotal="itemsTotal"
                  :page="page"
                  :possibleItemsPerPage="[[8, 16, 24]]"
                  :searchTerm="searchTerm"
                  :sortBy="data[0].sortBy"
                  :sortDesc="sortDesc"
                  @clearFilters="searchTerm = ''"
                  @update:check-all="handleSelectAll"
                  @update:checked-items="handleItemCheck"
                  @update:itemsPerPage="changePerPage"
                  @update:page="changePage"
                  @update:sortBy="changeSortBy"
                  @update:sortDesc="changeSortDesc"
                  :itemClass="dbItemClass"
                >
                  <template v-slot:no-data>
                    <TableFullScreenMessage
                      :title="$t('message.emptyTable.databases.database.title')"
                      :desc="$t('message.emptyTable.databases.database.description')"
                    >
                      <template v-slot:image>
                        <hosting-databases-illustration />
                      </template>
                      <template v-slot:action>
                        <v-btn
                          :large="!$vuetify.breakpoint.smAndDown"
                          :small="$vuetify.breakpoint.smAndDown"
                          elevation="0"
                          color="primary"
                          class="p-2 add-new-button"
                          @click="addButtons[tab].onClick"
                          :loading="data[tab].loading"
                        >
                          <v-icon
                            :class="$vuetify.breakpoint.mobile ? 'mr-0' : ''"
                            >$plus
                          </v-icon>
                          {{ $vuetify.breakpoint.mobile ? "" : $t('button.database.add') }}
                        </v-btn>
                      </template>
                    </TableFullScreenMessage>
                  </template>

                  <template v-slot:actions="item">
                    <v-tooltip
                      v-if="item.can_rename"
                      transition="custom-tooltip"
                      open-delay="150"
                      bottom
                      z-index="99"
                      offset-overflow
                      nudge-bottom="4px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-on="on"
                          v-bind="attrs"
                          icon
                          small
                          @click="showRenameModal(item)"
                        >
                          <v-icon>$edit2</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('button.database.edit') }}</span>
                    </v-tooltip>
                    <v-tooltip
                      transition="custom-tooltip"
                      open-delay="150"
                      bottom
                      z-index="99"
                      offset-overflow
                      nudge-bottom="4px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-on="on"
                          v-bind="attrs"
                          icon
                          small
                          @click="showDeleteModal(item)"
                        >
                          <v-icon>$thrash</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('button.database.delete') }}</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:mobile="{ item, headers }">
                    <div class="mobile-table-item__row">
                      <div
                        class="mobile-table-item__header"
                        @click="$emit('update:sortBy', headers[0])"
                      >
                        Database
                      </div>
                      <div class="mobile-table-item__value">
                        <span class="bold p-3">{{ item.database }}</span>
                      </div>
                    </div>
                    <div class="mobile-table-item__row">
                      <div
                        class="mobile-table-item__header"
                        @click="$emit('update:sortBy', headers[1])"
                      >
                        Size
                      </div>
                      <div class="mobile-table-item__value">
                        <span class="bold p-3">{{ item.disk_usage }}</span>
                      </div>
                    </div>
                  </template>
                  <template v-slot:updated_at="item">
                    <span>{{ item.updated_at_text }}</span>
                  </template>
                </hosting-table>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>

      <v-tab-item :transition="false" v-if="service.getUserPrivileges(service.privileges, 'hosting.manage_mysql')">
        <!-- USERS -->

        <v-container class="mt-3 px-0">
          <v-row>
            <v-col cols="12">
              <div :class="checkedItems.length > 0 ? 'pb-15' : ''">
                <hosting-table
                  :headers="data[1].headers"
                  :items="data[1].items"
                  :itemsLoading="data[1].loading"
                  :itemsPerPage="itemsPerPage"
                  :itemsTotal="itemsTotal"
                  :page="page"
                  :possibleItemsPerPage="[[8, 16, 24]]"
                  :searchTerm="searchTerm"
                  :sortBy="data[1].sortBy"
                  :sortDesc="sortDesc"
                  @clearFilters="searchTerm = ''"
                  @update:check-all="handleSelectAll"
                  @update:checked-items="handleItemCheck"
                  @update:itemsPerPage="changePerPage"
                  @update:page="changePage"
                  @update:sortBy="changeSortBy"
                  @update:sortDesc="changeSortDesc"
                  :itemClass="userItemClass"
                >
                  <template v-slot:no-data>
                    <TableFullScreenMessage
                      :title="$t('message.emptyTable.databases.users.title')"
                      :desc="$t('message.emptyTable.databases.users.description')"
                    >
                      <template v-slot:image>
                        <hosting-databases-illustration />
                      </template>
                      <template v-slot:action>
                        <v-btn
                          :large="!$vuetify.breakpoint.smAndDown"
                          :small="$vuetify.breakpoint.smAndDown"
                          elevation="0"
                          color="primary"
                          class="p-2 add-new-button"
                          @click="addButtons[tab].onClick"
                          :loading="data[tab].loading"
                        >
                          <v-icon
                            :class="$vuetify.breakpoint.mobile ? 'mr-0' : ''"
                            >$plus
                          </v-icon>
                          {{ $vuetify.breakpoint.mobile ? "" : $t('button.user.add') }}
                        </v-btn>
                      </template>
                    </TableFullScreenMessage>
                  </template>

                  <template v-slot:databases="item">
                    <ul>
                      <li v-for="(db, index) in item.databases" :key="index">
                        <v-tooltip v-model="db.showTooltip" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              text
                              small
                              v-bind="attrs"
                              v-on="on"
                              @click="loadDbPrivilegesModal(item, db)"
                            >
                              <v-icon>$database</v-icon>
                              {{ db.name }}
                            </v-btn>
                          </template>
                          <span
                            >Privileges:
                            <loader v-if="db.privileges === null" />
                            <ul v-else-if="db.privileges_preset != 'custom'">
                              <li>
                                <strong
                                  >-
                                  {{
                                    privilegesPresets()[db.privileges_preset]
                                      .label
                                  }}
                                </strong>
                              </li>
                            </ul>
                            <ul v-else>
                              <li
                                v-for="(priv, index) in db.privileges"
                                :key="index"
                              >
                                <strong>- {{ priv }}</strong>
                              </li>
                            </ul>
                          </span>
                        </v-tooltip>
                      </li>
                    </ul>
                  </template>

                  <template v-slot:actions="item">
                    <v-tooltip
                      v-if="privilegesSupported"
                      transition="custom-tooltip"
                      open-delay="150"
                      bottom
                      z-index="99"
                      offset-overflow
                      nudge-bottom="4px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-on="on"
                          v-bind="attrs"
                          icon
                          small
                          @click="loadUserPrivilegesModal(item)"
                          :loading="item.privilegesButtonLoading"
                        >
                          <v-icon>$database</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('button.database.managePrivileges') }}</span>
                    </v-tooltip>
                    <v-tooltip
                      transition="custom-tooltip"
                      open-delay="150"
                      bottom
                      z-index="99"
                      offset-overflow
                      nudge-bottom="4px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-on="on"
                          v-bind="attrs"
                          icon
                          small
                          @click="showEditUserModal(item, renameSupported)"
                        >
                          <v-icon>$edit2</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('button.user.edit') }}</span>
                    </v-tooltip>
                    <v-tooltip
                      transition="custom-tooltip"
                      open-delay="150"
                      bottom
                      z-index="99"
                      offset-overflow
                      nudge-bottom="4px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-on="on"
                          v-bind="attrs"
                          icon
                          small
                          @click="loadInfoModal(item)"
                          :loading="item.infoButtonLoading"
                        >
                          <v-icon>$help</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('button.showAccessDetails') }}</span>
                    </v-tooltip>
                    <v-tooltip
                      transition="custom-tooltip"
                      open-delay="150"
                      bottom
                      z-index="99"
                      offset-overflow
                      nudge-bottom="4px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-on="on"
                          v-bind="attrs"
                          icon
                          small
                          @click="showDeleteUserModal(item)"
                        >
                          <v-icon>$thrash</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('button.user.delete') }}</span>
                    </v-tooltip>
                  </template>

                  <template v-slot:mobile="{ item, headers }">
                    <div class="mobile-table-item__row">
                      <div
                        class="mobile-table-item__header"
                        @click="$emit('update:sortBy', headers[0])"
                      >
                        {{ $t('table.header.user') }}
                      </div>
                      <div class="mobile-table-item__value">
                        <span class="bold p-3">{{ item.user }}</span>
                      </div>
                    </div>
                  </template>
                  <template v-slot:updated_at="item">
                    <span>{{ item.updated_at_text }}</span>
                  </template>
                </hosting-table>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>

      <v-tab-item :transition="false" class="mb-4">
        <!-- PHPMyAdmin -->

        <v-container class="mt-3 px-0">
          <v-row>
            <v-col cols="12" class="pb-2">
              <v-card elevation="4">
                <v-card-text class="d-flex justify-space-between text--gray-darken2 v-card--mobile">
                  <div>
                    <h6>PHPMyAdmin</h6>
                    <p class="mb-0  mt-1">
                      {{ $t('heading.hosting.databases.phpmyadmin.subtitle') }}
                    </p>
                    <AlertSm :class="'alert-sm--primary mt-3'" :text="$t('heading.hosting.databases.phpmyadmin.alert')"/>
                  </div>

                  <div class="d-flex align-center">
                    <v-btn
                      elevation="0"
                      block
                      class="font-weight-bold v-btn--default outlined-button"
                      :href="'#'"
                      :target="'_blank'"
                      :loading="phpmyadminSsoLoading"
                      @click.prevent="phpmyadminSsoLogin(serverAccount.id, serverAccount.server_type)"
                    >
                      {{ $t('button.login.phpmyadmin') }}
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs-items>

    <v-dialog
      transition="custom-dialog-transition"
      :persistent="modalOptions.persistent"
      v-model="modalOptions.open"
      ref="dialog"
    >
      <div
        class="card-overlay"
        v-if="!modalOptions.persistent"
        @click="modalOptions.open = !modalOptions.open"
      />
      <div class="card-overlay" v-else @click="$refs.dialog.animateClick()" />
      <basic-modal
        style="width: 560px"
        :modalOptions="modalOptions"
        @modal-close="modalClose"
        :key="modalRender"
        ref="modal"
      />
    </v-dialog>
  </div>
</template>

<script>
import Api from "../../apis/Api";
import Helper from "../../apis/Helper";

import BasicModal from "@/components/modal/BasicModal";
import DataIteratorFilterControls from "../../components/dataIterator/DataIteratorFilterControls.vue";
import HostingTable from "../../components/dataIterator/tables/HostingTable.vue";
import TableFullScreenMessage from "../../components/dataIterator/tables/TableFullScreenMessage.vue";
import HostingDatabasesIllustration from "../../components/illustrations/hosting/hosting-databases-illustration.vue";
import AlertSm from "../../components/alerts/AlertSm.vue";

import ActionModalMixin from "@/mixins/ActionModalMixin";
import DataIteratorPageMixin from "../../mixins/DataIteratorPageMixin";
import CustomTablePageMixin from "../../mixins/CustomTablePageMixin";
import PHPMyAdminMixin from "../../mixins/PHPMyAdminMixin";
import Loader from "@/components/Loader";
import moment from "moment";
import ActiveServerAccountSelector from "@/components/ActiveServerAccountSelector.vue";

export default {
  components: {
    ActiveServerAccountSelector,
    BasicModal,
    DataIteratorFilterControls,
    HostingTable,
    TableFullScreenMessage,
    HostingDatabasesIllustration,
    Loader,
    AlertSm
  },
  mixins: [ActionModalMixin, DataIteratorPageMixin, CustomTablePageMixin, PHPMyAdminMixin],
  data() {
    return {
      isServerProvided: false,
      tab: 0,
      itemsPerPage: 8,
      page: 1,
      addButtons: [
        { text: this.$t('button.database.add'), onClick: this.showAddDatabaseModal },
        { text: this.$t('button.user.add'), onClick: this.loadAddUserModal },
        { text: "", onClick: this.loadAddUserModal },
      ],
      data: [
        {
          loading: true,
          sortDesc: true,
          items: [],
          search: "",
          sortBy: "updated_at",
          headers: [
            // DATABASES
            {
              text: 'database',
              value: "database",
              sortable: true,
            },
            {
              text: 'size',
              value: "disk_usage",
              sortable: true,
            },
            {
              text: 'updated',
              value: "updated_at",
              sortable: true,
            },
            {
              text: 'actions',
              value: "actions",
            },
          ],
        },
        // USERS
        {
          loading: true,
          sortDesc: true,
          items: [],
          search: "",
          sortBy: "updated_at",
          headers: [
            {
              text: 'user',
              value: "user",
              sortable: true,
            },
            {
              text: 'assignedDatabases',
              value: "databases",
            },
            {
              text: 'updated',
              value: "updated_at",
              sortable: true,
            },
            {
              text: 'actions',
              value: "actions",
            },
          ],
        },
        {
          loading: false,
          sortDesc: false,
          items: [],
          search: "",
          sortBy: "",
          headers: [
            {
              text: "",
              value: "",
              sortable: false,
            },
            {
              text: "",
              value: "",
              sortable: false,
            },
            {
              text: "",
              value: "",
              sortable: false,
            },
            {
              text: "",
              value: "",
            },
          ],
        },
      ],
      modalRender: 0,
      modalOptions: { open: false },
      highlightDbItem: {},
      highlightUserItem: {},
      requireDatabaseToCreateUser: false,
      privilegesSupported: false,
      renameSupported: false,
      serverAccount: null
    };
  },
  computed: {
    itemsTotal: function () {
      return this.data[this.tab].items.length;
    },
  },
  props: {
    service: Object
  },
  watch: {
    highlightItem: function (newValue) {
      if (!newValue) return;
      setTimeout(() => {
        this.highlightItem = {};
      }, 1000);
    },
    service: function () {
      this.serverAccount = this.service.server_accounts[0];
    },
    serverAccount: function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.reloadData();
      }
    },
    "data.1.items": {
      handler() {
        for (let user of this.data[1].items) {
          for (let db of user.databases) {
            if (db.showTooltip === true && db.privileges === null) {
              this.getDbPrivileges(user, db).then((privs) => {
                db.privileges = privs;
                db.privileges_preset = this.determinePreset(privs);
              });
            }
          }
        }
      },
      deep: true,
    },
    "modalOptions.open": function (value) {
      value
        ? this.$store.dispatch("lockBodyScroll")
        : this.$store.dispatch("unlockBodyScroll");
    },
  },
  methods: {
    async getDbPrivileges(user, db) {
      let response = await Api.cached().get(
        `/server-accounts/${this.serverAccount.id}/mysql/privileges/${
          user.user
        }/${encodeURIComponent(db.name)}`
      );
      return response.data.data;
    },
    showAddDatabaseModal() {
      this.resetModal();
      this.modalOptions.title = this.$t('heading.hosting.databases.modal.database.add.title');
      this.modalOptions.icon = "$plus";
      this.modalOptions.buttons.unshift({
        label: this.$t('button.database.add'),
        color: "primary",
        onclick: (modal) => {
          modal.$refs.form.validate() && this.addDatabase(modal.formValues);
        },
      });
      this.modalOptions.formFields = [
        {
          label: this.$t('form.label.database'),
          name: "name",
          tooltip: "tooltip.add_database.name",
          rules: [
            (v) => !!v ||
              this.$t('form.validation.required', {field: this.$t('form.label.database')}),
            (v) =>
              (v && v.length <= 64) ||
              this.$t('form.validation.maxLength', {
                field: this.$t('form.label.database'),
                length: 64
              }),
            (v) => (v && !v.includes(" ")) ||
              this.$t('form.validation.space', {field: this.$t('form.label.database')}),
          ],
          type: "text",
          prefix: this.serverAccount.mysql_db_prefix,
        },
      ];
      this.modalOptions.open = true;
    },
    addDatabase(formData) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";
      Api.post(
        `/server-accounts/${this.serverAccount.id}/mysql/databases`,
        formData
      )
        .then(() => {
          this.modalOptions.submittingSuccess = this.$t('notification.hosting.databases.database.add.success')
          this.highlightDbItem = {
            database: this.serverAccount.mysql_db_prefix + formData.name,
          };
          this.reloadDatabases();

          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.modalOptions.submittingSuccess,
          });
          this.modalOptions.open = false;
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);

          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.modalOptions.submittingError,
          });
        })
        .finally(() => {
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },
    showRenameModal(item) {
      this.resetModal();
      this.modalOptions.title = this.$t('heading.hosting.databases.modal.database.rename.title');
      this.modalOptions.icon = "$edit";
      this.modalOptions.buttons.unshift({
        label: this.$t('button.update'),
        color: "primary",
        onclick: (modal) => {
          modal.$refs.form.validate() &&
            this.renameDatabase(item, modal.formValues);
        },
      });
      this.modalOptions.formFields = [
        {
          label: this.$t('form.label.newName'),
          name: "name",
          tooltip: "tooltip.rename_database.name",
          rules: [
            (v) => !!v ||
              this.$t('form.validation.required', {field: this.$t('form.label.newName')}),
            (v) =>
              (v && v.length <= 64) ||
              this.$t('form.validation.maxLength', {
                field: this.$t('form.label.newName'),
                length: 64
              }),
            (v) => (v && !v.includes(" ")) ||
              this.$t('form.validation.space', {field: this.$t('form.label.newName')}),
            (v) => (v && v != item.database) ||
              this.$t('form.validation.different', {field: this.$t('form.label.newName')}),
          ],
          type: "text",
          prefix: this.serverAccount.mysql_db_prefix,
        },
      ];
      this.modalOptions.item = {
        name: item.database.split(this.serverAccount.mysql_db_prefix, 2)[1],
      };
      this.modalOptions.open = true;
    },
    renameDatabase(item, formData) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";
      Api.put(
        `/server-accounts/${
          this.serverAccount.id
        }/mysql/databases/${encodeURIComponent(item.database)}/name`,
        formData
      )
        .then(() => {
          this.modalOptions.submittingSuccess = this.$t('notification.hosting.databases.database.rename.success');
          this.highlightDbItem = {
            database: this.serverAccount.mysql_db_prefix + formData.name,
          };
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.modalOptions.submittingSuccess,
          });
          this.reloadDatabases();
          this.modalOptions.open = false;
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);

          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.modalOptions.submittingError,
          });
        })
        .finally(() => {
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },
    showDeleteModal(item) {
      this.resetModal();
      this.modalOptions.item = item;
      this.modalOptions.title = this.$t('heading.hosting.databases.modal.database.delete.title');
      this.modalOptions.color = "error";
      this.modalOptions.icon = "$alertwarning";

      this.modalOptions.formFields = [
        {
          message: `<b>${this.$t('message.confirmAction')}</b>`,
          label: this.$t('form.confirmDelete.databases.database'),
          name: "confirm",
          rules: [(v) => !!v || this.$t('form.validation.confirm')],
          type: "checkbox",
          required: true,
        },
      ];

      this.modalOptions.buttons.unshift({
        label: this.$t('button.delete'),
        color: "error",
        onclick: (modal) => {
          modal.$refs.form.validate() && this.deleteDatabase(item);
        },
      });
      this.modalOptions.open = true;
    },
    deleteDatabase(item) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";

      Api.delete(
        `/server-accounts/${
          this.serverAccount.id
        }/mysql/databases/${encodeURIComponent(item.database)}`
      )
        .then(() => {
          this.modalOptions.submittingSuccess = this.$t('notification.hosting.databases.database.delete.success');
          this.reloadDatabases();

          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.modalOptions.submittingSuccess,
          });
          this.modalOptions.open = false;
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);

          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.modalOptions.submittingError,
          });
        })
        .finally(() => {
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },
    loadAddUserModal() {
      Api.cached()
        .get(`/server-accounts/${this.serverAccount.id}/mysql/databases`)
        .then((response) => {
          this.showAddUserModal(response.data.data);
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        });
    },
    showAddUserModal(databases) {
      this.resetModal();
      this.modalOptions.title = this.$t('heading.hosting.databases.modal.user.add.title')
      this.modalOptions.icon = "$plus";
      this.modalOptions.buttons.unshift({
        label: this.$t('button.user.add'),
        color: "primary",
        onclick: (modal) => {
          modal.$refs.form.validate() && this.addUser(modal.formValues);
        },
      });

      this.modalOptions.formFields = [
        {
          label: this.$t('form.label.username'),
          name: "name",
          tooltip: "tooltip.add_user.username",
          type: "text",
          rules: [
            (v) => !!v || this.$t('form.validation.required', {field: this.$t('form.label.username')}),
            (v) =>
              (v && v.length <= 16) || this.$t('form.validation.minLength', {
                field: this.$t('form.label.username'),
                length: 16
              }),
            (v) => (v && !v.includes(" ")) ||
              this.$t('form.validation.space', {field: this.$t('form.label.username')}),
          ],
          prefix: this.serverAccount.mysql_db_prefix,
        },
        {
          label: this.$t('form.label.password'),
          name: "password",
          tooltip: "tooltip.add_user.password",
          rules: [
            (v) => !!v || this.$t('form.validation.required', {field: this.$t('form.label.password')}),
            (v) =>
              (v && v.length <= 64) ||
              this.$t('form.validation.maxLength', {
                field: this.$t('form.label.password'),
                length: 64
              }),
            (v) =>
              (v && !v.includes(" ")) ||
              this.$t('form.validation.space', {field: this.$t('form.label.password')}),
          ],
          type: "generatePassword",
        },
      ];

      if (this.requireDatabaseToCreateUser) {
        let info = databases.map(function (database) {
          return {
            label: database.database,
            value: database.database,
          };
        });
        this.modalOptions.formFields.unshift({
          label: this.$t('form.label.database'),
          name: "database",
          tooltip: "tooltip.add_user.database",
          type: "select",
          options: info,
        });
      }
      this.modalOptions.open = true;
    },
    addUser(formData) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";
      Api.post(
        `/server-accounts/${this.serverAccount.id}/mysql/users`,
        formData
      )
        .then(() => {
          this.modalOptions.submittingSuccess = this.$t('notification.hosting.databases.user.add.success');
          this.highlightUserItem = {
            user: this.serverAccount.mysql_db_prefix + formData.name,
          };
          this.reloadUsers();

          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.modalOptions.submittingSuccess,
          });
          this.modalOptions.open = false;
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);

          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.modalOptions.submittingError,
          });
        })
        .finally(() => {
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },
    showEditUserModal(item, renameSupported) {
      this.resetModal();
      this.modalOptions.item = item;
      this.modalOptions.title = this.$t('heading.hosting.databases.modal.user.update.title')
      this.modalOptions.icon = "$edit";
      this.modalOptions.buttons.unshift({
        label: this.$t('button.update'),
        color: "primary",
        onclick: (modal) => {
          modal.$refs.form.validate() && this.editUser(item, modal.formValues);
        },
      });
      this.modalOptions.formFields = [
        {
          label: this.$t('form.label.password'),
          name: "hiddenPassword",
          type: "hiddenPassword",
          tooltip: "tooltip.edit_user.password",

          onButtonClick: () => {
            for (const field of this.modalOptions.formFields) {
              if (field.name == "hiddenPassword") {
                field.hidden = true;
              }
              if (field.name == "password") {
                field.hidden = false;
              }
            }
          },
        },
        {
          label: this.$t('form.label.password'),
          name: "password",
          tooltip: "tooltip.edit_user.password",
          type: "generatePassword",
          rules: [
            (v) => {
              if (v === null) return true;
              if (!(v && v.length <= 64))
                return this.$t('form.validation.maxLength', {
                  field: this.$t('form.label.password'),
                  length: 64
                });
              if (!(v && !v.includes(" ")))
                return this.$t('form.validation.space', {field: this.$t('form.label.password')});
              return true;
            },
          ],
          hidden: true,
        },
      ];
      if (renameSupported) {
        this.modalOptions.formFields.push({
          label: this.$t('form.label.username'),
          name: "name",
          tooltip: "tooltip.edit_user.username",
          type: "text",
          rules: [
            (v) => !!v || this.$t('form.validation.required', {field: this.$t('form.label.username')}),
            (v) =>
              (v && v.length <= 16) || this.$t('form.validation.minLength', {
                field: this.$t('form.label.username'),
                length: 16
              }),
            (v) => (v && !v.includes(" ")) ||
              this.$t('form.validation.space', {field: this.$t('form.label.username')}),
            (v) => (v && v != item.user) ||
              this.$t('form.validation.different', {field: this.$t('form.label.username')}),
          ],
          prefix: this.serverAccount.mysql_db_prefix,
        });
      }
      this.modalOptions.item = {
        name: item.user.substring(this.serverAccount.mysql_db_prefix.length),
      };
      this.modalOptions.open = true;
    },
    editUser(item, formData) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";
      Api.put(
        `/server-accounts/${this.serverAccount.id}/mysql/users/${item.user}`,
        formData
      )
        .then(() => {
          this.modalOptions.submittingSuccess = this.$t('notification.hosting.databases.user.update.success');
          this.highlightUserItem = {
            user: this.serverAccount.mysql_db_prefix + formData.name,
          };
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.modalOptions.submittingSuccess,
          });
          this.reloadUsers();
          this.modalOptions.open = false;
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);

          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.modalOptions.submittingError,
          });
        })
        .finally(() => {
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },
    loadInfoModal(item) {
      item.infoButtonLoading = true;
      Api.cached()
        .get(`/server-accounts/${this.serverAccount.id}/mysql/server-info`)
        .then((response) => {
          this.showInfoModal(item, response.data.data);
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          item.infoButtonLoading = false;
        });
    },
    showInfoModal(item, info) {
      this.resetModal();
      this.modalOptions.item = item;
      this.modalOptions.title = this.$t('heading.hosting.databases.modal.user.show.title');
      this.modalOptions.icon = "$help";

      this.modalOptions.message = "<ul>";
      for (const [key, value] of Object.entries({
        "user": item.user,
        "host": info.host,
        "port": info.port,
      })) {
        const field = this.$t('heading.hosting.databases.modal.user.show.' + key);
        this.modalOptions.message += `<li><strong style="display:inline-block">${field}:</strong> <span style="display:inline-block">${value}</span></li>`;
      }
      this.modalOptions.message += "</ul>";

      this.modalOptions.open = true;
    },
    loadUserPrivilegesModal(item) {
      item.privilegesButtonLoading = true;
      let promises = [];
      for (let db of item.databases) {
        if (db.privileges === null) {
          promises.push(
            Api.cached()
              .get(
                `/server-accounts/${this.serverAccount.id}/mysql/privileges/${
                  item.user
                }/${encodeURIComponent(db.name)}`
              )
              .then((response) => {
                db.privileges = response.data.data;
                db.privileges_preset = this.determinePreset(response.data.data);
              })
          );
        }
      }
      Promise.all(promises)
        .then(() => {
          this.showUserPrivilegesModal(item);
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          item.privilegesButtonLoading = false;
        });
    },
    showUserPrivilegesModal(item) {
      this.resetModal();
      this.modalOptions.title = `Database Privileges: <span style="text-wrap: nowrap">${item.user}</span>`;
      this.modalOptions.icon = "$database";
      this.modalOptions.buttons.unshift({
        label: this.$t('button.update'),
        color: "primary",
        onclick: (modal) => {
          modal.$refs.form.validate() &&
            this.editDbPrivileges(item, modal.formValues);
        },
      });

      let databases = this.data[0].items.map((i) => {
        return {
          label: i.database,
          value: i.database,
        };
      });

      this.modalOptions.formFields = [
        {
          label: this.$t('form.label.database'),
          name: "database",
          tooltip: "tooltip.database_privileges.database",
          type: "select",
          options: databases,
          onChange: (values) => {
            let preset = "none";
            for (const db of item.databases) {
              if (db.name == values.database) {
                this.checkPrivileges(db.privileges);
                preset = db.privileges_preset;
                break;
              }
            }
            this.$refs.modal.setValue("privileges_preset", preset);
            this.showCheckboxes(preset != "custom");
          },
        },
        {
          label: this.$t('form.label.privileges'),
          name: "privileges_preset",
          tooltip: "tooltip.database_privileges.privileges",
          type: "select",
          options: [
            { label: this.$t('form.option.none'), value: "none" },
            { label: this.$t('form.option.read'), value: "read" },
            { label: this.$t('form.option.readWrite'), value: "read_write" },
            { label: this.$t('form.option.full'), value: "all" },
            { label: this.$t('form.option.custom'), value: "custom" },
          ],
          onChange: (values) => {
            this.showCheckboxes(values.privileges_preset != "custom");
          },
        },
      ];
      this.modalOptions.formFields = this.modalOptions.formFields.concat(
        this.privilegesFields()
      );
      let database = databases[0].value;
      if (item.databases.length) {
        database = item.databases[0].name;
      }
      let preset = "none";
      let privs = [];
      for (const db of item.databases) {
        if (db.name == database) {
          preset = db.privileges_preset;
          privs = db.privileges;
          break;
        }
      }
      this.showCheckboxes(preset != "custom");

      this.modalOptions.item = {
        database: database,
        privileges_preset: preset,
      };
      this.modalOptions.open = true;
      this.modalOptions.onMount = () => {
        this.checkPrivileges(privs);
      };
    },
    showDeleteUserModal(item) {
      this.resetModal();
      this.modalOptions.item = item;
      this.modalOptions.title = this.$t('heading.hosting.databases.modal.user.delete.title')
      this.modalOptions.color = "error";
      this.modalOptions.icon = "$alertwarning";

      this.modalOptions.formFields = [
        {
          message: `<b>${this.$t('message.confirmAction')}</b>`,
          label: this.$t('form.confirmDelete.databases.user'),
          name: "confirm",
          type: "checkbox",
          rules: [(v) => !!v || this.$t('form.validation.confirm')],
          required: true,
        },
      ];
      this.modalOptions.buttons.unshift({
        label: this.$t('button.delete'),
        color: "error",
        onclick: (modal) => {
          modal.$refs.form.validate() && this.deleteUser(item);
        },
      });
      this.modalOptions.open = true;
    },
    deleteUser(item) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";

      Api.delete(
        `/server-accounts/${this.serverAccount.id}/mysql/users/${item.user}`
      )
        .then(() => {
          this.modalOptions.submittingSuccess = this.$t('notification.hosting.databases.user.delete.success');
          this.reloadUsers();

          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.modalOptions.submittingSuccess,
          });
          this.modalOptions.open = false;
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);

          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.modalOptions.submittingError,
          });
        })
        .finally(() => {
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },
    privileges() {
      return [
        "ALTER",
        "ALTER ROUTINE",
        "CREATE",
        "CREATE ROUTINE",
        "CREATE TEMPORARY TABLES",
        "CREATE VIEW",
        "DELETE",
        "DROP",
        "EVENT",
        "EXECUTE",
        "INDEX",
        "INSERT",
        "LOCK TABLES",
        "REFERENCES",
        "SELECT",
        "SHOW VIEW",
        "TRIGGER",
        "UPDATE",
      ];
    },
    privilegesPresets() {
      return {
        none: { label: this.$t('form.option.none'), privs: [] },
        read: { label: this.$t('form.option.read'), privs: ["SELECT"] },
        read_write: {
          label: this.$t('form.option.readWrite'),
          privs: ["SELECT", "DELETE", "INSERT", "UPDATE"],
        },
        all: { label: this.$t('form.option.full'), privs: ["ALL PRIVILEGES"] },
      };
    },
    determinePreset(privs) {
      for (const [name, preset] of Object.entries(this.privilegesPresets())) {
        if (this.compareArrays(preset.privs, privs)) {
          return name;
        }
      }
      return "custom";
    },
    compareArrays(a1, a2) {
      const a2Sorted = a2.slice().sort();
      return (
        a1.length === a2.length &&
        a1
          .slice()
          .sort()
          .every(function (value, index) {
            return value === a2Sorted[index];
          })
      );
    },
    checkAll(bool) {
      let newValues = {};
      newValues["ALL PRIVILEGES"] = bool;
      for (const priv of this.privileges()) {
        newValues[priv] = bool;
      }
      this.$refs.modal.setValues(newValues);
    },
    checkPrivileges(privs) {
      for (const priv of this.privileges()) {
        this.$refs.modal.setValue(priv, privs.includes(priv));
      }
    },
    showCheckboxes(bool) {
      for (let field of this.modalOptions.formFields) {
        if (field.type == "checkbox") {
          field.hidden = bool;
        }
      }
    },
    privilegesFields() {
      let formFields = [
        {
          label: this.$t('form.value.privilege.all'),
          name: "ALL PRIVILEGES",
          type: "checkbox",
          onChange: (values) => {
            this.checkAll(values["ALL PRIVILEGES"]);
          },
        },
      ];
      for (const priv of this.privileges()) {
        formFields.push({
          label: priv,
          name: priv,
          type: "checkbox",
          onChange: (values) => {
            if (!values[priv]) {
              this.$refs.modal.setValue("ALL PRIVILEGES", false);
            }
          },
        });
      }
      return formFields;
    },
    async loadDbPrivilegesModal(item, db) {
      if (db.privileges === null) {
        await this.getDbPrivileges(item, db);
      }
      this.showDbPrivilegesModal(item, db);
    },
    showDbPrivilegesModal(item, db) {
      this.resetModal();
      this.modalOptions.title = this.$t('heading.hosting.databases.modal.user.editPrivilege.title', {database: db.name})
      this.modalOptions.icon = "$edit";
      this.modalOptions.buttons.unshift({
        label: this.$t('button.update'),
        color: "primary",
        onclick: (modal) => {
          modal.$refs.form.validate() &&
            this.editDbPrivileges(item, {
              ...modal.formValues,
              ...{ privileges_preset: "custom", database: db.name },
            });
        },
      });
      this.modalOptions.formFields = this.privilegesFields();
      if (db.privileges.includes("ALL PRIVILEGES")) {
        this.modalOptions.onMount = () => {
          this.checkAll(true);
        };
      } else {
        let modalItem = {};
        for (const priv of this.privileges()) {
          modalItem[priv] = db.privileges.includes(priv);
        }
        this.modalOptions.item = modalItem;
      }
      this.modalOptions.open = true;
    },
    editDbPrivileges(item, formData) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";

      let privileges = [];
      if (formData.privileges_preset == "custom") {
        if (formData["ALL PRIVILEGES"]) {
          privileges.push("ALL PRIVILEGES");
        } else {
          for (const priv of this.privileges()) {
            if (formData[priv]) {
              privileges.push(priv);
            }
          }
        }
      } else {
        privileges = this.privilegesPresets()[formData.privileges_preset].privs;
      }

      let method = "put";
      if (!privileges.length) {
        method = "delete";
      }
      Api[method](
        `/server-accounts/${this.serverAccount.id}/mysql/privileges/${
          item.user
        }/${encodeURIComponent(formData.database)}`,
        { privileges: privileges.join() }
      )
        .then(() => {
          this.modalOptions.submittingSuccess = this.$t('notification.hosting.databases.user.updatePrivilege.success')
          this.highlightUserItem = item;
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.modalOptions.submittingSuccess,
          });
          this.modalOptions.open = false;
          this.reloadUsers();
          Api.cached().clear(
            `/server-accounts/${this.serverAccount.id}/mysql/privileges/${
              item.user
            }/${encodeURIComponent(formData.database)}`
          );
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);

          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.modalOptions.submittingError,
          });
        })
        .finally(() => {
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },
    reloadDatabases() {
      this.data[0].loading = true;
      this.data[0].items = [];
      Api.get(`/server-accounts/${this.serverAccount.id}/mysql/databases`)
        .then((response) => {
          this.data[0].items = response.data.data.map((item) => {
            item.disk_usage = Helper.formatBytes(item.disk_usage);
            item.updated_at_text = item.updated_at
              ? moment(item.updated_at).fromNow()
              : "-";
            item.can_rename = response.data.meta?.actions?.rename || false;
            return item;
          });
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.data[0].loading = false;
        });
    },
    reloadUsers() {
      this.data[1].loading = true;
      this.data[1].items = [];
      Api.get(`/server-accounts/${this.serverAccount.id}/mysql/users`)
        .then((response) => {
          this.data[1].items = response.data.data.map((item) => {
            item.databases = item.databases.map((db) => {
              return {
                name: db,
                privileges: null,
                showTooltip: false,
              };
            });
            item.updated_at_text = item.updated_at
              ? moment(item.updated_at).fromNow()
              : "-";
            item.infoButtonLoading = false;
            item.privilegesButtonLoading = false;
            return item;
          });

          this.privilegesSupported = response.data.meta.privileges_supported;
          this.requireDatabaseToCreateUser =
            response.data.meta.require_database_to_create;
          this.renameSupported = response.data.meta.rename_supported;
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.data[1].loading = false;
        });
    },
    reloadData() {
      if (this.serverAccount.diagnostic_mode) {
        this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: this.$t("message.hostingAccountInDiagnosticMode"),
        });
        this.data[0].loading = false;
        this.data[1].loading = false;
        this.data[2].loading = false;
        return;
      }
      if (this.service.getUserPrivileges(this.service.privileges, 'hosting.manage_mysql')) {
        this.reloadDatabases();
        this.reloadUsers();
      }
    },
    changeSortBy: function (data) {
      this.page = 1;
      this.data[this.tab].sortBy = data;
    },
    dbItemClass(item) {
      if (
        this.highlightDbItem.database &&
        item.database == this.highlightDbItem.database
      ) {
        return "highlight";
      }
    },
    userItemClass(item) {
      if (
        this.highlightUserItem.user &&
        item.user == this.highlightUserItem.user
      ) {
        return "highlight";
      }
    },
  },
  mounted() {
    if (this.service) {
      this.serverAccount = this.service.server_accounts[0];
    }
  },
};
</script>

<style scoped lang="scss">
.title-row {
  @media (min-width: 1401px) {
    height: 70px;
  }
}
.v-card--mobile{
  @media (max-width: 767px){
    flex-direction: column;
    .v-btn--default{
      margin-top: 16px;
    }
  }
}
.v-window::v-deep {
  // overflow: visible !important;
  background-color: transparent;

  padding: 8px 32px 16px 32px;
  margin: -8px -32px -16px -32px;
  width: calc(100% + 64px);
  max-width: unset;

  .v-window-item,
  .v-window__container {
    overflow: visible !important;
  }
}

.v-tab {
  min-width: unset;
  max-height: 46px;
}

.v-tabs::v-deep {
  max-height: 46px;
}

.outlined-button {
  background: unset !important;
  color: var(--v-text-darken2);
  &::before {
    background-color: unset !important;
  }
}
</style>
